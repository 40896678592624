<template>
  <div>
    <!-- Display the iframe once the analytics URL is loaded -->
    <div v-if="isLoadedAnalyticsUrl">
      <iframe
        v-resize="{ log: true }"
        class="border-0"
        title="Makeke Dashboard"
        width="100%"
        :src="makekeDashboardUrl"
      />
    </div>
    <!-- Display a loading spinner while the analytics URL is being fetched -->
    <template v-else>
      <b-card class="text-center p-4">
        <b-spinner />
      </b-card>
    </template>
  </div>
</template>

  <script>
  import { BSpinner, BCard } from 'bootstrap-vue'
  import { apiToastError } from '@/@core/utils/toast'
  import analytics from '@/@core/utils/analytics'
  import constants from '@/constants'

  export default {
    name: 'Analytics',
    components: {
      BCard,
      BSpinner,
    },
    data() {
      return {
        // Flag to track whether the analytics URL is loaded
        isLoadedAnalyticsUrl: false,
        // The URL to be displayed in the iframe
        makekeDashboardUrl: '',
      }
    },
    created() {
      // Trigger the method to fetch the analytics URL when the component is created
      this.getAnalyticsUrl()
    },
    destroyed() {
      // Track the page view when the component is destroyed
      analytics.track(constants.TRACKS.PAGES.BRAND_EXITS_ANALYTICS_PAGE)
    },
    methods: {
      // Fetch the analytics URL using a KP request
      async getAnalyticsUrl() {
        try {
          const response = await this.$kpRequest({
            ...this.$kpEndpoint.integrations.makeke,
          })

          // Set the URL and update the flag once the request is successful
          this.makekeDashboardUrl = response?.data?.data?.makekeUrl
          this.isLoadedAnalyticsUrl = true
          // Track the page view
          analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_ANALYTICS_PAGE)
        } catch (error) {
          apiToastError(error)
        }
      },
    },
  }
  </script>
